//import logo from './logo.svg';
import './App.css';
import logo_web_02_png from './assets/images/logo_web_02_png.png';
import QLA_nome_texto_claro from './assets/images/QLA_nome_texto_claro.png';
import ico_instagram_claro from './assets/images/ico_instagram_claro.png';


//Firebase
import { initializeApp } from "firebase/app";
//

function App() {

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAjkxQIUT1uYUUmSMhftemGEFxn2opLxxE",
    authDomain: "qlaweb-46d89.firebaseapp.com",
    projectId: "qlaweb-46d89",
    storageBucket: "qlaweb-46d89.appspot.com",
    messagingSenderId: "418573241417",
    appId: "1:418573241417:web:e8a2f726a6d878a8308398"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo_web_02_png} className="App-logo" alt="QLA" />
        <img src={QLA_nome_texto_claro} className="App-logo-texto" alt="QLA" />
        <p>
          Sua melhor versão, com semijoias.
        </p>

        <div>
          <img src={ico_instagram_claro} className="App-ico-insta " alt="QLA" />

          <a
            className="App-link"
            href="https://www.instagram.com/queluxoamiga/"
            target="_blank"
            rel="noopener noreferrer"
          >
            QLA no Instagram
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
